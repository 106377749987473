import { Component } from "react";
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { publicApiRequest, getLPParams } from "../redux/actionsAPI";
import Button from "react-bootstrap/esm/Button";


class DashboardBrowser extends Component {
    textFields = [["firstName", "First Name"],["lastName", "Last Name"], ["email", "email"], ["jobTitle", "Position"], ["companyName", "Company"]]

    state = Object.assign({
        "intervalID": null,
        "newComment": "",
        "reggedEmail": "",
        termsAccepted: false,
    }, ...this.textFields.map ((el) => ({ [el[0]]:"" }))) 

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleCBChange = e => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
    };


    timer() {
        if (this.props.interface.liveStream && this.props.interface.liveStream.isLive)
            this.props.dispatch (publicApiRequest({'endpoint': 'updateLiveStream', 'sID': this.props.urlParams.sID}, null, true))
    }

    componentDidMount() {
        this.props.dispatch (getLPParams())
        if (this.props.sID)
        {
            this.props.dispatch (publicApiRequest({'endpoint': 'startLiveStream', 'sID': this.props.urlParams.sID}))
            this.setState({intervalID: setInterval(this.timer.bind(this), 60*1000)});
        }
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.urlParams.sID) && this.props.urlParams.sID) {
            this.props.dispatch (publicApiRequest({'endpoint': 'startLiveStream', 'sID': this.props.urlParams.sID}))
            this.setState({intervalID: setInterval(this.timer.bind(this), 60*1000)});
        }
        if (this.props.lpParams && this.props.lpParams.title)
            document.title = this.props.lpParams.title + " Live Stream"
    }

    componentWillUnmount() {
        if (this.state.intervalID)
            clearInterval(this.state.intervalID);
    }
     

    render () {
        var that = this

        if (this.props.interface.fullScreenMessage)
            return <div className="gContainer">
                <div className="loginContainer">
                    <h2>{this.props.interface.fullScreenMessage}</h2>
                </div>
            </div>

        if (!this.props.urlParams.sID)
        {
            var fieldsFilled = true
            return <div className="gContainer">
                <div className="loginContainer">
                    <h2>Welcome to the {this.props.lpParams && this.props.lpParams.title ? this.props.lpParams.title : "EBS"} live-streaming platform</h2>
                    <h4>Are you already registered to the event? Then access with your e-mail below:</h4>
                    <div className="regForm">
                        {[["reggedEmail", "email"]].map (function (fn, ic) {
                            return <div className="control" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]} <span className='asterisk'>*</span></label>
                                <input
                                    className="form-control"
                                    name={fn[0]}
                                    onChange={that.handleInputChange}
                                    value={that.state[fn[0]]}
                                    type="text"
                                />
                            </div>
                        })}
                        <div className="control mt-1 buttonsContainer">
                            <button className={"float-right btn btn-primary" + ((that.state.reggedEmail && that.state.reggedEmail.length > 4) ? "" : " disabled")} disabled={!(that.state.reggedEmail && that.state.reggedEmail.length > 4) } onClick={(e)=>{
                                        this.props.dispatch (publicApiRequest({'endpoint': 'loginToLive', 'newState': that.state}))           
                            }}>Send me my access link</button>
                        </div>
                    </div>
                    <h4>Not yet registered? Please register:</h4>
                    <div className="regForm">
                        {this.textFields.map (function (fn, ic) {
                            if ((!that.state[fn[0]]) || that.state[fn[0]].length < 2)
                                fieldsFilled = false
                            return <div className="control" key={"tf" + ic}>
                                <label htmlFor={fn[0]} className="form-label">{fn[1]} <span className='asterisk'>*</span></label>
                                <input
                                    className="form-control"
                                    name={fn[0]}
                                    onChange={that.handleInputChange}
                                    value={that.state[fn[0]]}
                                    type="text"
                                />
                            </div>
                        })}
                        
                        <div className="control">
                            <input
                                className="form-check-input"
                                name="termsAccepted"
                                onChange={that.handleCBChange}
                                checked={that.state["termsAccepted"]}
                                type="checkbox"
                            />
                            <label htmlFor="termsAccepted" className="form-check-label ms-2"> I Accept EBS <a href="https://ebsummit.eu/wp-content/uploads/2021/04/EBS_conditions.pdf" target="_blank">Terms and Conditions</a> and <a href="https://ebsummit.eu/wp-content/uploads/2021/04/Privacy-policy.pdf" target="_blank">Privacy policy</a></label>
                        </div>
                                               
                        <div className="control mt-1 buttonsContainer">
                            <button className={"float-right btn btn-primary" + (this.state.termsAccepted && fieldsFilled ? "" : " disabled")} disabled={!this.state.termsAccepted && fieldsFilled} onClick={(e)=>{
                                        this.props.dispatch (publicApiRequest({'endpoint': 'registerToLive', 'newState': that.state}))           
                            }}>Register</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        }

        var chat = null
        if (this.props.interface.liveStream && this.props.interface.liveStream.chatEnabled) 
        {
            var announcements = this.props.interface.liveStream.comments ? this.props.interface.liveStream.comments.map (function (p, ic) {
                return <div key={"ann" + ic} className="mb-1 ann">
                    <h5>{p.text} - <span className="meta">{p.by} ({p.date})</span> </h5>
                </div>
            }):null

            chat = <div className="ChatItems">
                {this.props.interface.liveStream && this.props.interface.liveStream.liveSessionHeader ? <h2>{this.props.interface.liveStream.liveSessionHeader}</h2>:null}
                <h4>Live chat:</h4>
                <div className="control mb-2">
                        <input
                            id="newComment"
                            className="form-control"
                            name="newComment"
                            onChange={that.handleInputChange}
                            value={that.state["newComment"]}
                            type="text"
                        />
                        <Button variant="primary" className={"mt-3" + (that.state["newComment"].length < 10 ? " disabled":"")} disabled={that.state["newComment"].length < 10} onClick={(e)=>{ this.props.dispatch (publicApiRequest({'endpoint': 'addLiveComment', "text": that.state.newComment, "sID": that.props.urlParams.sID})); that.setState ({newComment:""}) }}>
                            Send my question
                        </Button>
                </div>
                <div className="messages">
                    {announcements}
                </div>
            </div>
            
        }

        var twoCols = this.props.interface.liveStream && ((this.props.interface.liveStream.liveSessionHeader && this.props.interface.liveStream.liveSessionHeader.length > 3) || chat )
        return <div className="LiveBrowser">
            <Row >
                {this.props.interface.liveStream?<Col  className="videoContainer" md={twoCols ? 9 : 12}><div dangerouslySetInnerHTML={{__html: this.props.interface.liveStream.streamCode}} /></Col>:null}
                {twoCols ? <Col md={3}>{chat ? chat : (this.props.interface.liveStream && this.props.interface.liveStream.liveSessionHeader ? <h2>{this.props.interface.liveStream.liveSessionHeader}</h2>:null) }</Col>:null}
            </Row>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        urlParams: state.interfaceParams.urlParams,
        lpParams: state.interfaceParams.lpParams
    }
}

export default connect(mapStateToProps)(DashboardBrowser)