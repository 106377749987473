export const LOADING = 'LOADING'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const MODIFY_INTERFACE_PARAM = "MODIFY_INTERFACE_PARAM"

export function setLoading (isLoading, loadingProgress) {
    return {
        type: LOADING,
        isLoading,
        loadingProgress
    }
}

export function setNotification (header=null, content=null, nclass='error') {
    return {
        type: SET_NOTIFICATION,
        header,
        content,
        nclass
    }
}

