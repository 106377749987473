import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import Container from 'react-bootstrap/Container';

import DashboardBrowser from '../interfaceParts/DashboardBrowser'

import Notification from '../interfaceParts/Notification'

import { MODIFY_INTERFACE_PARAM } from '../redux/actions'

class InternalPage extends Component {
  getJsonFromUrl(url) {
    var query = url.substr(1);
    var result = {};
    query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  componentDidMount() {
    this.props.dispatch ({type: MODIFY_INTERFACE_PARAM, paramName:"urlParams", paramValue:this.getJsonFromUrl (document.location.search)}  )
  }

  render() {
    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    var contents = null
    if (this.props.pageType === 'DashboardPage')
      contents = <DashboardBrowser />
    
    return (
      <div className={"App"}>
        <div className='header'>
          {this.props.lpParams && this.props.lpParams.logo ? 
            <img width={"200px"} src={this.props.lpParams.logo} />:null}
        </div>
          <LoadingSpinner show={this.props.loading} />
          <Container fluid>
                    {contents}
          </Container>
          <Notification/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.interfaceParams.loading,
    lpParams: state.interfaceParams.lpParams
  }
}


export default connect(mapStateToProps)(InternalPage)


