import { combineReducers } from 'redux'
import {
    LOADING,
    SET_NOTIFICATION,
    MODIFY_INTERFACE_PARAM
} from '../actions'

function interfaceParams(
    state = {
        loading: false,
        loadingProgress: null,
        notificationHeader: null,
        notificationContent: null,
        notificationClass: null,
        urlParams: {},
        fullScreenMessage: null,
        lpParams: null
    },
    action
) {
    switch (action.type) {
        case LOADING:
            if (action.isLoading)
                    return Object.assign({}, state, {
                        notificationHeader: null,
                        notificationContent: null,
                        notificationClass: null,
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
                else
                    return Object.assign({}, state, {
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
        case SET_NOTIFICATION:
            return Object.assign({}, state, {
                notificationHeader: action.header,
                notificationContent: action.content,
                notificationClass: action.nclass,
            })
        case MODIFY_INTERFACE_PARAM:
            var cs = Object.assign({}, state, {})
            cs[action.paramName] = action.paramValue
            return cs
        default:
            return state
  }

}

export default combineReducers({
    interfaceParams
})