import { 
    setNotification,
    setLoading,
 } from "./actions"

import { MODIFY_INTERFACE_PARAM } from "./actions"

export const GLOBAL_URL = process.env["REACT_APP_BACKEND_URL"]

export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.notification && (response.notification.length > 0))
        dispatch(setNotification (response.notificationHeader ? response.notificationHeader : "Notification", response.notification))
    if (response.interfaceAction)
        dispatch({type:MODIFY_INTERFACE_PARAM, "paramName": response.interfaceAction.paramName, "paramValue": response.interfaceAction.paramValue})
}

export function publicApiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator = false) {
    return dispatch => {
        if (!withoutLoadingIndicator)
            dispatch(setLoading(true))
        return fetch(GLOBAL_URL + "papi", conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (!result.error && extraExecOnSuccess)
                    {
                        if (extraExecOnSuccess.constructor === Array)
                            for (var extra of extraExecOnSuccess)
                                dispatch (extra)
                        else
                            dispatch(extraExecOnSuccess)
                    }
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                },
                (error) => {
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                }
            )
    }
}

export function publicApiRequest (body, extraExecOnSuccess = null, withoutLoadingIndicator = false) {
    const conf = {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    return publicApiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator)   
}

export function getLPParams () {
    return dispatch => {
        dispatch(setLoading(true))
        return fetch("https://ebsummits.eu/api/livepageparams", { method: "get"})
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch({type:MODIFY_INTERFACE_PARAM, "paramName": "lpParams", "paramValue": result})
                    dispatch(setLoading(false))
                },
                (error) => {
                    dispatch(setLoading(false))
                }
            )
    }
}